// Generated by Framer (838580a)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/CAjjxbTJBxHwH1MagCef/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);

const enabledGestures = {WY2c9PJoj: {hover: true}};

const cycleOrder = ["WY2c9PJoj"];

const serializationHash = "framer-ZvV5f"

const variantClassNames = {WY2c9PJoj: "framer-v-61nkvi"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "WY2c9PJoj", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-61nkvi", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"WY2c9PJoj"} ref={ref ?? ref1} style={{backgroundColor: "rgb(72, 71, 71)", borderBottomLeftRadius: 4, borderBottomRightRadius: 4, borderTopLeftRadius: 4, borderTopRightRadius: 4, ...style}} {...addPropertyOverrides({"WY2c9PJoj-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-6tb0ux"} data-framer-name={"icon_wrapper"} layoutDependency={layoutDependency} layoutId={"nP6he2bzo"}><ComponentViewportProvider ><motion.div className={"framer-rfm7q4-container"} layoutDependency={layoutDependency} layoutId={"Ph6fN3XCs-container"}><Phosphor color={"var(--token-4b770c3b-28b6-4e61-bfaf-595faa53b7a9, rgb(217, 217, 217))"} height={"100%"} iconSearch={"arrow-up-right"} iconSelection={"House"} id={"Ph6fN3XCs"} layoutId={"Ph6fN3XCs"} mirrored={false} selectByList={false} style={{height: "100%", width: "100%"}} weight={"light"} width={"100%"}/></motion.div></ComponentViewportProvider><ComponentViewportProvider ><motion.div className={"framer-1qz81eh-container"} layoutDependency={layoutDependency} layoutId={"Aq_diahkH-container"}><Phosphor color={"var(--token-4b770c3b-28b6-4e61-bfaf-595faa53b7a9, rgb(217, 217, 217))"} height={"100%"} iconSearch={"arrow-up-right"} iconSelection={"House"} id={"Aq_diahkH"} layoutId={"Aq_diahkH"} mirrored={false} selectByList={false} style={{height: "100%", width: "100%"}} weight={"light"} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ZvV5f.framer-1k6f0tu, .framer-ZvV5f .framer-1k6f0tu { display: block; }", ".framer-ZvV5f.framer-61nkvi { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: 28px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 28px; will-change: var(--framer-will-change-override, transform); }", ".framer-ZvV5f .framer-6tb0ux { align-content: center; align-items: center; aspect-ratio: 1 / 1; display: flex; flex: none; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: var(--framer-aspect-ratio-supported, 20px); justify-content: flex-start; overflow: hidden; padding: 0px; position: relative; width: 20px; }", ".framer-ZvV5f .framer-rfm7q4-container, .framer-ZvV5f .framer-1qz81eh-container { flex: none; height: 20px; position: relative; width: 20px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-ZvV5f.framer-61nkvi, .framer-ZvV5f .framer-6tb0ux { gap: 0px; } .framer-ZvV5f.framer-61nkvi > *, .framer-ZvV5f .framer-6tb0ux > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-ZvV5f.framer-61nkvi > :first-child, .framer-ZvV5f .framer-6tb0ux > :first-child { margin-top: 0px; } .framer-ZvV5f.framer-61nkvi > :last-child, .framer-ZvV5f .framer-6tb0ux > :last-child { margin-bottom: 0px; } }", ".framer-ZvV5f.framer-v-61nkvi.hover.framer-61nkvi { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 28px); }", ".framer-ZvV5f.framer-v-61nkvi.hover .framer-6tb0ux { justify-content: flex-end; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 28
 * @framerIntrinsicWidth 28
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"HMWjPN4Rm":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerCQsPpHMZz: React.ComponentType<Props> = withCSS(Component, css, "framer-ZvV5f") as typeof Component;
export default FramerCQsPpHMZz;

FramerCQsPpHMZz.displayName = "arrow icon";

FramerCQsPpHMZz.defaultProps = {height: 28, width: 28};

addFonts(FramerCQsPpHMZz, [{explicitInter: true, fonts: []}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})